import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Posts from '../utils/Posts'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../utils/PageHeader'

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressPage(wordpress_id: { eq: 135 }) {
        title
        content
        featured_media {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        yoast_meta {
          yoast_wpseo_title
          yoast_wpseo_metadesc
        }
      }
      allWordpressPost(sort: { fields: date, order: DESC }) {
        edges {
          node {
            date(formatString: "MMM DD, YYYY")
            title
            excerpt
            slug
            link
            featured_media {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      parallax: file(relativePath: { eq: "1hero.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Blog"
        keywords={['blog']}
        description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
      />
      <PageHeader
        img={data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
      >
        <div className="container">
          <h1 className="portfolio-heading">{data.wordpressPage.title}</h1>
        </div>
      </PageHeader>
      <section style={{ marginTop: '50px' }}>
        <div className="container">
          <div className="row">
            <Posts
              posts={data.allWordpressPost.edges}
              parallax={data.parallax}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPage
